import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  useState,
} from 'react'

import { styled } from '@linaria/react'
import { defineMessages, useIntl } from 'react-intl'

import { Input } from 'components/designSystem/Input/Input'
import { PasswordEyeSvg } from 'components/designSystem/svgr/PasswordEyeSvg'

export const InputPassword: FC<{
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  onKeyDown: KeyboardEventHandler<HTMLInputElement>
  disabled?: boolean
  invalid?: boolean
  errorText?: string
}> = ({ value, onChange, onKeyDown, disabled, invalid, errorText }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const intl = useIntl()

  const togglePasswordVisible = () => {
    setPasswordVisible((visible) => !visible)
  }

  return (
    <StyledInput
      name="password"
      type={passwordVisible ? 'text' : 'password'}
      value={value}
      placeholder={intl.formatMessage(messages.password)}
      onChange={onChange}
      onKeyDown={onKeyDown}
      required
      disabled={disabled}
      invalid={invalid}
      errorText={errorText}
      elementRight={
        <PasswordEye
          onClick={togglePasswordVisible}
          data-name="toggle-password-visibility-action"
        >
          <PasswordEyeSvg crossedEye={!passwordVisible} />
        </PasswordEye>
      }
    />
  )
}

const messages = defineMessages({
  password: {
    id: 'form.password_placeholder',
    defaultMessage: 'Пароль',
  },
})

const StyledInput = styled(Input)`
  margin-top: var(--spacing-16px);

  input {
    padding-right: 40px;
  }

  input[type='password']::-ms-reveal {
    display: none;
  }
`
const PasswordEye = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`
