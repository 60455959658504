import React, { ComponentProps, FC } from 'react'

import { cx, css } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithChildren, WithClassName } from 'common/types'

import { buttonResetCss } from '../styles/buttonResetCss'

export const RoundButton: FC<
  { size: Size; interactive?: boolean } & WithChildren &
    ComponentProps<typeof RoundButtonInner> &
    WithClassName
> = ({ size, interactive = true, children, className, ...restProps }) => {
  return (
    <RoundButtonInner
      className={cx(
        className,
        sizeStylesMap[size],
        interactive && interactiveCss
      )}
      {...restProps}
      as={interactive ? undefined : 'div'}
    >
      {children}
    </RoundButtonInner>
  )
}

type Size = 'XL' | 'L' | 'M' | 'S' | 'XS'

const interactiveCss = css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--accent-primary-hover);
    }
  }

  &:active {
    background-color: var(--accent-primary-press);
  }

  &:disabled {
    background: var(--background-opacity-disabled);
    cursor: default;
  }
`

const RoundButtonInner = styled.button`
  ${buttonResetCss};
  background-color: var(--accent-primary-default);
  transition: 0.2s;
`

const sizeStylesMap: Record<Size, string> = {
  XL: css`
    padding: var(--spacing-20px);
    border-radius: var(--spacing-48px);
  `,
  L: css`
    padding: var(--spacing-16px);
    border-radius: var(--spacing-48px);
  `,
  M: css`
    padding: var(--spacing-12px);
    border-radius: var(--spacing-48px);
  `,
  S: css`
    padding: var(--spacing-8px);
    border-radius: var(--spacing-80px);
  `,
  XS: css`
    padding: var(--spacing-6px);
    border-radius: var(--spacing-80px);
  `,
}
