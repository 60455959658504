import React, { ComponentProps, FC, MutableRefObject } from 'react'

import { styled } from '@linaria/react'

import { commonHandlersMap } from 'actions/uninotice/commonUniNoticeActions'
import { UniNoticePayload } from 'api/uninotice/uninotice.types'
import { VoidHandler } from 'common/types'
import {
  BottomSheet,
  BottomSheetRefHandlers,
} from 'components/designSystem/BottomSheet/BottomSheet'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { Typography } from 'components/designSystem/Typography/Typography'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { uniNoticeReducer } from 'reducers/uniNoticeReducer'

export const OpenVendorAppBottomSheet: FC<{
  noticeId: string
  bottomSheetRef: MutableRefObject<BottomSheetRefHandlers>
  onClosed?: VoidHandler
  desktopPortal?: ComponentProps<typeof BottomSheet>['desktopPortal']
}> = ({ noticeId, bottomSheetRef, onClosed, desktopPortal }) => {
  useReducersInsert({ uniNoticeReducer })

  const dispatch = useAppDispatch()
  const { notice } = useShallowEqualSelector(
    ({ uniNoticeReducer: { collection } }) => ({
      notice: collection[noticeId],
    })
  )

  const payload = notice?.payload as UniNoticePayload

  const handleClick = () => {
    const handler = commonHandlersMap[
      payload.actionBtn.action.actionId
    ] as VoidHandler
    dispatch(handler(payload.actionBtn.action.data))
  }

  return (
    <BottomSheet
      ref={bottomSheetRef}
      title={<TitleMobile>{payload?.textTitle}</TitleMobile>}
      onClosed={onClosed}
      desktopPortal={desktopPortal}
      centerContentVerticallyOnDesktop
    >
      {notice && (
        <Inner>
          <IconDesktop
            src={notice.payload.image?.svgUrl}
            width={96}
            height={96}
          />
          <TitleDesktop fontSize={24} fontWeight={700}>
            {payload?.textTitle}
          </TitleDesktop>
          <Text fontSize={16}>{payload.text}</Text>
          <PrimaryButtonWrapper>
            <PrimaryButton
              onClick={handleClick}
              fullWidth
              data-name="vendor-action"
            >
              <IconInButtonMobile
                src={notice.payload.image?.svgUrl}
                width={24}
                height={24}
              />
              {payload.actionBtn.title}
            </PrimaryButton>
          </PrimaryButtonWrapper>
        </Inner>
      )}
    </BottomSheet>
  )
}

const TitleMobile = styled.span`
  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
const TitleDesktop = styled(Typography)`
  margin-top: var(--spacing-24px);
  margin-bottom: var(--spacing-24px);

  @media screen and (max-width: ${breakpoints.mobile - 1}px) {
    display: none;
  }
`
const IconInButtonMobile = styled.img`
  html[dir='ltr'] & {
    margin-right: var(--spacing-8px);
  }

  html[dir='rtl'] & {
    margin-left: var(--spacing-8px);
  }

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
const IconDesktop = styled.img`
  display: flex;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.mobile}px) {
    display: none;
  }
`
const Text = styled(Typography)`
  text-align: center;
`
const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`
const PrimaryButtonWrapper = styled.div`
  width: 100%;
  margin-top: var(--spacing-18px);

  @media screen and (max-width: ${breakpoints.mobile}px) {
    width: calc(100% + 16px);
    margin-left: -8px;
    margin-right: -8px;
  }
`
