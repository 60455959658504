import React, { FC } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'
import { FormattedMessage } from 'react-intl'

import { VoidHandler } from 'common/types'

import { PhoneVerificationAction, PhoneVerificationMethod } from './constants'

export const TimerText: FC<{
  phoneVerificationMethod: PhoneVerificationMethod
  phoneVerificationAction: PhoneVerificationAction | null
  onChangeVerificationMethod?: VoidHandler
  onResendCode: VoidHandler
  seconds: number
  error?: boolean
  loading: boolean | undefined
}> = ({
  seconds,
  phoneVerificationMethod,
  phoneVerificationAction,
  error,
  onChangeVerificationMethod,
  onResendCode,
  loading,
}) => {
  if (error) {
    const classNames = cx(seconds > 0 && notAllowedCss, loading && loadingCss)

    const remainingText =
      seconds > 0 ? (
        <FormattedMessage
          id="app.in_seconds"
          defaultMessage="через {seconds} секунд"
          values={{ seconds }}
        />
      ) : null

    return (
      <ErrorText>
        <FormattedMessage
          id="app.entered_incorrect_code_or_expired"
          defaultMessage="Введён некорректный или устаревший проверочный код."
        />

        {phoneVerificationAction === PhoneVerificationAction.ChangeMethod && (
          <ButtonAsLink
            className={classNames}
            onClick={onChangeVerificationMethod}
            as="p"
            data-name={TRY_ANOTHER_METHOD_DATA_TEST_NAME}
          >
            <FormattedMessage
              id="app.try_another_method_text"
              defaultMessage="Попробовать другой способ {remainingText}"
              values={{ remainingText }}
            />
          </ButtonAsLink>
        )}

        {phoneVerificationAction === PhoneVerificationAction.ResendCode && (
          <ButtonAsLink
            className={classNames}
            onClick={onResendCode}
            as="p"
            data-name={RESEND_CODE_DATA_TEST_NAME}
          >
            <FormattedMessage
              id="app.resend.code"
              defaultMessage="Отправить повторно {remainingText}"
              values={{ remainingText }}
            />
          </ButtonAsLink>
        )}
      </ErrorText>
    )
  }

  if (seconds > 0) {
    switch (phoneVerificationMethod) {
      case PhoneVerificationMethod.Sms:
      case PhoneVerificationMethod.MobileId:
        return (
          <FormattedMessage
            id="app.you_will_receive_code_in_seconds"
            defaultMessage="Ты получишь сообщение в течение{br}{seconds} секунд"
            values={{ seconds, br: <br /> }}
          />
        )

      case PhoneVerificationMethod.VoiceCode:
      case PhoneVerificationMethod.Call:
        return (
          <FormattedMessage
            id="app.you_will_receive_call_in_sec"
            defaultMessage="Звонок поступит в течение{br}{seconds} секунд"
            values={{ seconds, br: <br /> }}
          />
        )
    }
  }

  if (!seconds) {
    const classNames = cx(loading && loadingCss)

    const changeMethodText = (
      <ButtonAsLink
        onClick={onChangeVerificationMethod}
        className={classNames}
        data-name={TRY_ANOTHER_METHOD_DATA_TEST_NAME}
      >
        <FormattedMessage
          id="app.try_another_method"
          defaultMessage="Попробовать другой способ"
        />
      </ButtonAsLink>
    )
    const resendCodeText = (
      <ButtonAsLink
        onClick={onResendCode}
        className={classNames}
        data-name={RESEND_CODE_DATA_TEST_NAME}
      >
        <FormattedMessage
          id="form.button.email_resend"
          defaultMessage="Отправить повторно"
        />
      </ButtonAsLink>
    )

    const text =
      phoneVerificationAction === PhoneVerificationAction.ResendCode
        ? resendCodeText
        : changeMethodText

    switch (phoneVerificationMethod) {
      case PhoneVerificationMethod.Call:
      case PhoneVerificationMethod.VoiceCode:
        return (
          <FormattedMessage
            id="app.did_not_get_call"
            defaultMessage="Звонка не было? {text}"
            values={{ text }}
          />
        )

      default:
        return (
          <FormattedMessage
            id="app.did_not_get_message"
            defaultMessage="Не получил сообщение? {text}"
            values={{ text }}
          />
        )
    }
  }
}

const RESEND_CODE_DATA_TEST_NAME = 'resend-code-action'
const TRY_ANOTHER_METHOD_DATA_TEST_NAME =
  'try-another-verification-method-action'

export const ErrorText = styled.span`
  color: var(--error-default);
`
export const ButtonAsLink = styled.span`
  color: var(--info-soft);
  cursor: pointer;
`
const notAllowedCss = css`
  cursor: not-allowed;
  pointer-events: none;
`
const loadingCss = css`
  cursor: default;
`
