import React, { SVGProps } from 'react'

export const ArrowRightSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.382 55.79a4 4 0 0 1 .7-5.613l21.404-16.661c.674-.525.687-1.54.027-2.082L18 13.756a4 4 0 0 1 5.079-6.181l21.514 17.676c4.622 3.798 4.528 10.902-.192 14.577l-21.405 16.66a4 4 0 0 1-5.613-.699Z"
      clipRule="evenodd"
    />
  </svg>
)
