import { localeParameter } from 'api/api.constants'
import { fetchQueryApi } from 'api/fetchApi'
import { paths } from 'api/generated/profile'
import { NodeHeaders } from 'api/NodeHeaders'
import {
  favouritesStatus,
  geoCoordsStatus,
  onlineStatus,
  ProfileStatus,
  realStatus,
  spaceTimeLocationStatus,
  statusNamesParameter,
  verifiedPhotoStatus,
  vipStatus,
  winkStatus,
} from 'api/profile/profile.functions'
import { Status } from 'api/search/search.types'

export interface UserOptionalProfileResult {
  statuses: Record<ProfileStatus, Status>
}

type Method = paths['/profiles/{aid}']['get']
type QueryParameters = Method['parameters']['query']
export type Result = Method['responses']['200']['schema']

export const fetchUserOptionalProfileApi = (
  profileId: number,
  statuses: ProfileStatus[],
  hit?: number,
  headers?: NodeHeaders,
  locale?: string
) => {
  return fetchQueryApi<
    Result,
    QueryParameters & { [localeParameter]?: string }
  >(
    `/profiles/${profileId}` as keyof paths,
    {
      [statusNamesParameter]: statuses.join(','),
      source: hit,
      [localeParameter]: locale,
    },
    {
      headers,
    }
  )
}

export const fetchUserProfileApi = (
  userId: number,
  hit?: number,
  headers?: NodeHeaders,
  locale?: string
) => {
  return fetchQueryApi<
    Result,
    QueryParameters & { [localeParameter]?: string }
  >(
    `/profiles/${userId}` as keyof paths,
    {
      photo: true,
      [statusNamesParameter]: [
        onlineStatus,
        vipStatus,
        realStatus,
        geoCoordsStatus,
        favouritesStatus,
        verifiedPhotoStatus,
        spaceTimeLocationStatus,
        winkStatus,
      ].join(','),
      source: hit,
      [localeParameter]: locale,
    },
    {
      headers,
    }
  )
}
