import React, { Children, FC, useRef } from 'react'

import { css, cx } from '@linaria/core'
import { styled } from '@linaria/react'

import { WithChildren, WithClassName } from 'common/types'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

import { applyIfAnimationsEnabledCss } from '../styles/applyIfAnimationsEnabledCss'

export const StepSlider: FC<
  {
    step: number
  } & WithChildren &
    WithClassName
> = ({ className, step, children }) => {
  const { rtl } = useShallowEqualSelector(({ systemReducer: { rtl } }) => ({
    rtl,
  }))
  const contentRef = useRef<HTMLDivElement>(null)
  const childrenCount = Children.count(children)

  return (
    <Content
      className={className}
      ref={contentRef}
      style={{ transform: `translateX(-${step * 100}%)` }}
    >
      <Slider style={{ width: `${childrenCount * 100}%` }}>
        {Children.map(children, (child, index) => {
          const translateX = `translateX(${index < step ? -GAP : GAP}px)`

          return (
            <Slide
              {...{ inert: step === index ? undefined : '' }}
              style={{ transform: index === step ? 'none' : translateX }}
              className={cx(rtl && rtlCss)}
            >
              {child}
            </Slide>
          )
        })}
      </Slider>
    </Content>
  )
}

/** Гэп между слайдами */
const GAP = 200
const TRANSFORM = 'transform 1.5s ease-in-out'

const rtlCss = css`
  direction: rtl;
`
const animationCss = `
  ${applyIfAnimationsEnabledCss(`
    transition: ${TRANSFORM};
    will-change: transform;
  `)}
`
const Content = styled.div`
  direction: ltr; /* Форсируем ltr, чтобы слайдер прокручивался для всех языков одинаково */
  width: 100%;
  height: 100%;
  ${animationCss};
`
const Slider = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
`
const Slide = styled.div`
  width: 100%;
  height: 100%;
  ${animationCss};
`
